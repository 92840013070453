import produce from 'immer'
import { cloneDeep } from 'lodash'

import { fetchRoute } from '~/lib/util/fe/fetchRoute'

import useEveryActionStore, { everyActionInitialState } from '.'

export const loadCommittees = async (campaignId: number) => {
  useEveryActionStore.setState(
    produce((state) => {
      state.loading.committees = true
    })
  )
  const { data, isSuccess } = await fetchRoute(
    `/api/campaigns/${campaignId}/everyaction/committees`,
    'GET',
    {
      parseSuccessResponse: true
    }
  )
  if (isSuccess) {
    useEveryActionStore.setState(
      produce((state) => {
        state.committees = (data?.committees ?? []).sort((a, b) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        )
        state.loading.committees = false
      })
    )
  }
}

export const resetEveryActionStore = () => {
  useEveryActionStore.setState({ ...everyActionInitialState })
}

export const setEveryActionGroups = (
  resourceType: string,
  resources: any[]
) => {
  useEveryActionStore.setState(
    produce((state) => {
      state.groups[resourceType] = cloneDeep(resources)
    })
  )
}
