import { createContext } from 'react'

import { CurrentCampaign } from '~/types/campaigns'
import type { CurrentUser } from '~/types/users'

import { FilterData } from '../../fe/filters'

export interface AuthContextType {
  authorizedValues: {
    user: CurrentUser | null
    campaign: CurrentCampaign
    initialized: boolean
    memberCount: number
  }
  filterData: FilterData
  errorCodes: any[]
  protectPage: (initialized: boolean, user: any, requireAdmin?: boolean) => void
  updateAuthorizedValues: () => void
  forceRefresh: () => void
  refreshFilterData: () => void
  showNavMenu: boolean
  updateFilterData: (campaignId) => void
  updateMemberCount: (campaignId) => void
  updateShowNavMenu: (show: boolean) => void
}

export const AuthContext = createContext<AuthContextType>({
  authorizedValues: {
    user: null,
    campaign: null,
    initialized: false,
    memberCount: null
  },
  protectPage: (i, u) => {},
  updateAuthorizedValues: () => {},
  forceRefresh: () => {},
  filterData: null,
  errorCodes: null,
  refreshFilterData: () => {},
  showNavMenu: false,
  updateFilterData: (campaignId) => {},
  updateMemberCount: (campaignId) => {},
  updateShowNavMenu: (show) => {}
})
