import { ApolloClient, InMemoryCache } from '@apollo/client'

const digitalDomain =
  typeof window !== 'undefined'
    ? window?.location?.host?.includes('digital')
    : false

let host
if (process.env.RIPPLE_ENV === 'development') {
  host = 'http://localhost:3000'
} else if (process.env.RIPPLE_ENV === 'staging') {
  host = digitalDomain
    ? 'https://ripple-staging.strivedigital.org'
    : 'https://ripple-staging.strivemessaging.org'
} else if (process.env.RIPPLE_ENV === 'production') {
  host = digitalDomain
    ? 'https://app.strivedigital.org'
    : 'https://app.strivemessaging.org'
} else if (process.env.RIPPLE_ENV?.startsWith('qa-')) {
  host = digitalDomain
    ? `https://${process.env.RIPPLE_ENV}.staging.strivedigital.org`
    : `https://${process.env.RIPPLE_ENV}.staging.strivemessaging.org`
}

const client = new ApolloClient({
  uri: `${host}/api/graphql`,
  cache: new InMemoryCache()
})

export default client
