import { resetEveryActionStore } from './useEveryActionStore/actions'
import { resetGlobalStore } from './useGlobalStore/actions'
import { resetInboxStore } from './useInboxStore/actions'

export const initializeStores = (isUserChange = true) => {
  if (isUserChange) {
    resetGlobalStore()
  }
  resetInboxStore()
  resetEveryActionStore()
}
