import '../styles/styles.css' //tailwind
import '../public/static/legacy.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'setimmediate'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import Head from 'next/head'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SWRConfig } from 'swr'

import client from '~/lib/util/fe/apollo-client'
import AuthContextWrapper from '~/lib/util/fe/dataContext/authenticationWrapper'
import fetcher from '~/lib/util/fe/swr-fetch'

Sentry.init({
  dsn: 'https://08fbe008461a448790ff2fa9f4ad143f@o258897.ingest.sentry.io/1453728',
  environment: process.env.RIPPLE_ENV,
  enabled: process.env.RIPPLE_ENV !== 'development',
  ignoreErrors: [/.*unrecognized host.*/i]
})

class RippleApp extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        {' '}
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <DndProvider backend={HTML5Backend}>
          <SWRConfig
            value={{
              refreshInterval: 20000,
              fetcher
            }}
          >
            <ApolloProvider client={client}>
              <AuthContextWrapper>
                <Component {...pageProps} />
              </AuthContextWrapper>
            </ApolloProvider>
          </SWRConfig>
        </DndProvider>
      </>
    )
  }
}

export default RippleApp
