import { useLazyQuery } from '@apollo/client'
import { getMemberCountForCampaign } from '@lib/util/fe/client-models/data'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import useLocalStorage from '~/hooks/useLocalStorage'
import { getUserAndCampaign, redirectRoute } from '~/lib/util/fe/authorization'
import {
  formatStatusCodes,
  formatUserData,
  GET_ERROR_CODE_VALUES_QUERY
} from '~/lib/util/fe/filters'

import { AuthContextType } from '../lib/util/fe/dataContext/authenticationContext'
import { initializeStores } from './stores/initializeStores'
import useGlobalStore from './stores/useGlobalStore'

const protectPage = (initialized, user, requireAdmin = false) => {
  if (initialized && user === null) {
    redirectRoute(null, '/sign-in')
  } else if (requireAdmin && initialized && user && !user.isAdmin) {
    redirectRoute(null, '/campaigns')
  }
}

const getFilterData = async (campaignId) => {
  const [
    broadcasts,
    flowData,
    tzData,
    targets,
    automatedMemberData,
    organizerData
  ] = await Promise.all([
    fetch(`/api/campaigns/${campaignId}/broadcast-names`),
    fetch(`/api/campaigns/${campaignId}/flow-names`),
    fetch(`/api/campaigns/${campaignId}/timezones`),
    fetch(`/api/${campaignId}/callTargets`),
    fetch(`/api/campaigns/${campaignId}/representatives`),
    fetch('/api/organizers')
  ])
  return {
    broadcasts: await broadcasts.json(),
    flowData: (await flowData.json())?.flows,
    tzData: (await tzData.json()).timezones,
    targets: await targets.json(),
    automatedMemberData: await automatedMemberData.json(),
    organizerData: formatUserData(await organizerData.json()),
    campaignId
  }
}

export const useAuth = (): AuthContextType => {
  const [currentUser, setCurrentUser] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [memberCount, setMemberCount] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const [debounceId, setDebounceId] = useState(null)

  const [fetchFilterData, setFetchFilterData] = useState(null)
  const [fetchMemberCount, setFetchMemberCount] = useState(null)
  const [filterData, setFilterData] = useState(null)
  const [errorCodes, setErrorCodes] = useState(null)

  // Local state
  const [showNavMenu, setShowNavMenu] = useLocalStorage('nav-menu-expand', true)

  const router = useRouter()

  // When the route changes,
  // If there is not a current user, or if there is not a campaign or if the
  // campaign id does not match the current campaign, fetch current user and campaign.
  useEffect(() => {
    if (
      router.isReady &&
      (!currentUser || !campaign || router.query.campaignId != campaign.id)
    ) {
      refreshData()
    }
  }, [router, router.pathname])

  const needsRefresh = () => {
    if (debounceId) {
      clearTimeout(debounceId)
    }
    const nextId = setTimeout(refreshData, 500)
    setDebounceId(nextId)
  }

  const refreshData = async () => {
    setMemberCount(null)
    setFetchMemberCount(null)
    const campaignId = parseInt(router.query.campaignId as string) || null
    const { campaign, currentUser, isUserAuthorized } =
      await getUserAndCampaign(null, campaignId)
    // Campaign was not authorized, redirect to 404 unless there is not a user present.
    // This is to prevent double nav, because all campaign pages require a user.
    if (campaignId && !isUserAuthorized && currentUser) {
      redirectRoute(null, '/404')
    }
    setCampaign(campaign)
    setCurrentUser(currentUser)

    const currentCampaignId = useGlobalStore.getState().campaign?.id
    useGlobalStore.setState({ campaign, user: currentUser })

    // re-initialize stores if campaign is switched
    if (
      (currentCampaignId || campaign?.id) &&
      currentCampaignId !== campaign?.id
    ) {
      initializeStores(false)
    }

    setInitialized(true)
  }

  /**
   * This method forces a refresh of the filter data for the existing campaign. It differs
   * from the updateFilterData by not relying on fetchFilterData changing values.
   */
  const refreshFilterData = async () => {
    if (campaign) {
      if (!errorCodes) getErrorCodes()
      getFilterData(campaign.id).then((value) => {
        setFilterData(value)
      })
    }
  }

  // Error Code data is account-wide, and only needs fetching once.
  // Its a lazy query so doesn't fire until its called via updateFilterData
  const [getErrorCodes, { data }] = useLazyQuery(GET_ERROR_CODE_VALUES_QUERY)

  useEffect(() => {
    if (data) {
      let codes = formatStatusCodes(data)
      setErrorCodes(codes)
    }
  }, [data])

  /**
   * This method looks for the 'fetchFilterData' key to change, and when it detects
   * a campaign id that does not match the current campaign id, it will fetch the correct one.
   * This is lazily loaded, so won't fetch until some page or component calls updateFilterData
   */
  useEffect(() => {
    if (fetchFilterData && filterData?.campaignId != fetchFilterData) {
      getFilterData(fetchFilterData).then((value) => {
        setFilterData(value)
      })
    }
  }, [fetchFilterData])

  /**
   * This method looks for the 'fetchMemberCount' key to change, and when it detects
   * a campaign id that does not match the current campaign id, it will fetch the correct one.
   * This is lazily loaded, so won't fetch until some page or component calls updateFilterData
   */
  useEffect(() => {
    if (
      fetchMemberCount &&
      (memberCount === null || filterData?.campaignId != fetchMemberCount)
    ) {
      getMemberCountForCampaign(fetchMemberCount).then((value) => {
        setMemberCount(value)
      })
    }
  }, [fetchMemberCount])

  /**
   * This method is exposed via the context, and can be called to trigger fetching of data.
   * @param campaignId the ID of the campaign to fetch data for
   */
  const updateFilterData = (campaignId) => {
    // these are org wide and won't really change so get once
    if (!errorCodes) getErrorCodes()
    setFetchFilterData(campaignId)
  }

  const updateMemberCount = (campaignId) => {
    setFetchMemberCount(campaignId)
  }

  const updateShowNavMenu = (show: boolean) => {
    setShowNavMenu(show)
  }

  return {
    authorizedValues: { campaign, user: currentUser, initialized, memberCount },
    updateFilterData,
    updateMemberCount,
    updateAuthorizedValues: needsRefresh,
    protectPage: protectPage,
    forceRefresh: refreshData,
    errorCodes,
    filterData,
    refreshFilterData,
    showNavMenu,
    updateShowNavMenu
  }
}
