import useInboxStore, { ClusterSelectionControl, inboxInitialState } from '.'

export const resetInboxStore = () => {
  useInboxStore.setState(inboxInitialState)
}

export const setErrorMessage = (message) => {
  useInboxStore.setState({ errorMessage: message })
}

export const setSelectedMember = (member) => {
  useInboxStore.setState({ selectedMember: member })
}

export const setUsers = (users) => {
  useInboxStore.setState({ users: users })
}

export const setSelectedConversation = (conversation) => {
  useInboxStore.setState({ selectedConversation: conversation })
}

export const incrementForceInboxRefresh = () => {
  useInboxStore.setState((state) => {
    state.forceInboxRefresh++
  })
}

export const setSelectedConversationClusters = (
  clusterControl: ClusterSelectionControl
) => {
  useInboxStore.setState({ selectedConversationClusters: clusterControl })
}
