import Router from 'next/router'

import type { CurrentCampaign } from '~/types/campaigns'
import type { CurrentUser } from '~/types/users'

import fetch from './fetch'

export const redirectRoute = (res, url: string) => {
  if (res) {
    res.writeHead(302, { Location: url })
    return res.end()
  } else {
    Router.replace(url)
  }
}

// Switching - returns null if not authorized
export const getCurrentUser = async (ctx) => {
  const data = await fetch('/api/current-user', { ctx })

  if (data.status === 200) {
    const user = (await data.json()) as CurrentUser
    return user
  }

  return null
}

// Switching - returns null if no campaign id, returns false if not authorized
// Returns campaign if found.
export const getCurrentCampaign = async (ctx, campaignId) => {
  let campaign = null
  let isUserAuthorized = true
  if (campaignId) {
    const campaignData = await fetch('/api/campaigns/' + campaignId, { ctx })
    if (campaignData.status === 200) {
      campaign = (await campaignData.json()) as CurrentCampaign
    } else if (campaignData.status === 403) {
      isUserAuthorized = false
    }
  }
  // Not found
  return { campaign, isUserAuthorized }
}

export const getUserAndCampaign = async (ctx, campaignId?: number) => {
  const [currentUser, { campaign, isUserAuthorized }] = await Promise.all([
    getCurrentUser(ctx),
    getCurrentCampaign(ctx, campaignId)
  ])

  // don't proceed, redirect to sign-in
  if (!currentUser) return { currentUser: null, campaign: null }

  return {
    campaign,
    currentUser,
    isUserAuthorized
  }
}

export const authorizePageAccess = async (ctx, campaignId?: number) => {
  let { currentUser, campaign, isUserAuthorized } = await getUserAndCampaign(
    ctx,
    campaignId
  )

  // don't proceed, redirect to sign-in
  if (!currentUser || currentUser.archived_at) {
    // No user, redirect to sign in.
    redirectRoute(ctx?.res, '/sign-in')
    return { currentUser: null, campaign: null }
  }

  // if campaign comes back as false, not authorized, so redirect to 404
  if (!isUserAuthorized) {
    campaign = null
    redirectRoute(ctx?.res, '/404')
  }

  return {
    campaign,
    currentUser
  }
}

export const authorizePageAccessAdmin = async (ctx) => {
  const currentUser = await getCurrentUser(ctx)
  if (!currentUser) return { currentUser: null }

  if (!currentUser.isAdmin) {
    redirectRoute(ctx?.res, '/campaigns')
    return { currentUser: null }
  }

  return {
    currentUser
  }
}
