import { useAuth } from '~/hooks/useAuth'

import { AuthContext } from './authenticationContext'

export const AuthContextWrapper = (props) => {
  const context = useAuth()

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextWrapper
