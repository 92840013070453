import type { FetchRouteOptions } from '~/types/fetch'

export const fetchRoute = async (
  route: string,
  method: string = 'GET',
  options: FetchRouteOptions = {}
) => {
  let data = null
  const { body, headers, parseErrorResponse, parseSuccessResponse } =
    options ?? {}

  try {
    const response = await fetch(route, {
      method,
      ...(body
        ? {
            body,
            headers: headers ?? {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        : {
            ...(headers ?? {})
          })
    })
    if ([200, 204].includes(response.status)) {
      if (parseSuccessResponse) {
        data = await response.json()
      }
      return { data, status: response.status, isSuccess: true }
    } else {
      if (parseErrorResponse) {
        data = await response.json()
      }
      return { data, status: response.status, isSuccess: false }
    }
  } catch (error) {
    console.error(error)
  }
  return { data, status: null, isSuccess: false }
}

export const sortOrderMap = {
  ascending: 'ASC',
  descending: 'DESC'
}

export const getPagingUri = (
  offset: number,
  limit: number,
  search: any,
  orderBy: string,
  optionalQueryParams: string = null
) => {
  let paging = `?offset=${offset}&limit=${limit}&orderBy=${encodeURIComponent(
    orderBy
  )}`
  if (search && search.trim() !== '') {
    paging = paging + '&search=' + search
  }
  if (optionalQueryParams) {
    paging = paging + optionalQueryParams
  }

  return paging
}
