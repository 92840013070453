import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import createSelectors from '~/hooks/stores/createSelectors'
import { CurrentCampaign } from '~/types/campaigns'
import { CurrentUser } from '~/types/users'

export const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'strive-user-preferences'

export interface GlobalStore {
  campaign: CurrentCampaign
  user: CurrentUser
  userPreferences: { hideMemberChangeLogInConversationView?: boolean }
}

const getInitialPreferences = () => {
  try {
    if (typeof window === 'undefined') return {}
    const item = window.localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY)
    return item ? JSON.parse(item) : {}
  } catch (error) {
    return {}
  }
}

export const globalInitialState: GlobalStore = {
  campaign: null,
  user: null,
  userPreferences: getInitialPreferences()
}

const useGlobalStoreBase = create<GlobalStore>()(
  immer((set) => ({
    ...globalInitialState
  }))
)

const useGlobalStore = createSelectors(useGlobalStoreBase)

export default useGlobalStore
