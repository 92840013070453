import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { Flow } from '~/graphql/generated/types-and-hooks'
import createSelectors from '~/hooks/stores/createSelectors'
import type { ConversationCluster, InboxMember } from '~/types/inbox'

export type ClusterSelectionControl = {
  [key: string]: { cluster: ConversationCluster; exclusions: number[] }
}

export type InboxStore = {
  errorMessage: string
  fetchingMemberId: number
  selectedMember: InboxMember
  selectedConversation: Flow
  selectedConversationClusters: ClusterSelectionControl
  forceInboxRefresh: number
  users: any[]
}

export const inboxInitialState: InboxStore = {
  errorMessage: '',
  fetchingMemberId: null,
  selectedMember: null,
  selectedConversation: null,
  selectedConversationClusters: null,
  users: [],
  forceInboxRefresh: 0
}

const useInboxStoreBase = create<InboxStore>()(
  immer((set) => ({
    ...inboxInitialState
  }))
)

const useInboxStore = createSelectors(useInboxStoreBase)

export default useInboxStore
